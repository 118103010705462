// content/twoImagesSection.js

import image1 from '../assets/images/twoImagesSection/for-Buyers-orders.jpg';
import image2 from '../assets/images/twoImagesSection/for-suppliers-orders.jpg';

const twoImagesSectionContent = {
  image1: image1,
  title1: 'For buyers',
  subtitle1: 'Specify product details',
  subtitle2: 'Agree on price and delivery',
  subtitle3: 'Send order to supplier',
  subtitle4: 'Manage payment',
  subtitle5: 'Track order',
  image2: image2,
  title2: 'For sellers',
  subtitle6: 'Receive product request',
  subtitle7: 'Propose price & clarify details',
  subtitle8: 'Receive order',
  subtitle9: 'Confirm payment',
  subtitle10: 'Start production',
};

export default twoImagesSectionContent;
