// components/ordersIcons/ordersIcons.js

import React from 'react';
import PropTypes from 'prop-types';
import ordersIconsContent from '../../content/ordersIcons';
import './ordersIcons.scss';

const OrdersIcons = () => {
  return (
    <div className='box-center'>
      <div className="orders-icons-container">
        {ordersIconsContent.map(icon => (
          <div key={icon.id} className="icon-wrapper">
            <img src={icon.image} alt={`Icon ${icon.id}`} />
            <b className={'font-size-18 ln-21 d-flex align-items-center'}>{icon.text}</b>
          </div>
        ))}
      </div>
    </div>
  );
};

OrdersIcons.propTypes = {};

export default OrdersIcons;