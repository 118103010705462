import React from 'react';
import './vimeoModule.scss';

const VimeoModule = ({ videoUrl, title, subtitle }) => {
  return (
    <div className='vimeo-module'>
      <div className='vimeo-video-wrapper'>
        <h2 className='video-title'>{title}</h2>
        <p className='video-subtitle'>{subtitle}</p>
        <iframe
          src={videoUrl}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title={title}
        ></iframe>
      </div>
    </div>
  );
};

export default VimeoModule;
