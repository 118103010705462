// content/ordersIcons.js

import icon01 from '../assets/images/ordersIcons/icon01.svg';
import icon02 from '../assets/images/ordersIcons/icon02.svg';
import icon03 from '../assets/images/ordersIcons/icon03.svg';
import icon04 from '../assets/images/ordersIcons/icon04.svg';
import icon05 from '../assets/images/ordersIcons/icon05.svg';

const ordersIconsContent = [
  {
    id: 1,
    image: icon01,
    text: 'Find the right seller',
  },
  {
    id: 2,
    image: icon02,
    text: 'Agree on details with seller',
  },
  {
    id: 3,
    image: icon03,
    text: 'Place your order',
  },
  {
    id: 4,
    image: icon04,
    text: 'Make a secure payment',
  },
  {
    id: 5,
    image: icon05,
    text: 'Track your order',
  },
];

export default ordersIconsContent;
