//import Cover from '../assets/images/covers/general-page-background.svg'
import Image01 from '../assets/images/orders-page/specify-your-desired-product-design.png'
import Image02 from '../assets/images/orders-page/order-management-made-easy.png'
import Image03 from '../assets/images/orders-page/digital-handshake-confidence.png'
import Image04 from '../assets/images/orders-page/fast-secure-payments.png'
import background1 from '../assets/images/cta/background1.jpg'

export const contentorders = (t) => {
  return {
    columnsCover: {
    },
    columns1: {
      direction: 'reverse',
      sizes: [5, 6],
      illustration: {
        src: Image01,
        alt: 'Specify Your Desired Product Design',
      },
      text: {
        title: 'Specify Your Desired Product Design',
      },
      list: [
        'Specify your own product or select from the seller´s showroom.',
        'Choose colors, materials, sizes, and specs step-by-step.',
        'Enjoy flexible and user-friendly customization options.',
      ],
      buttons: [
        {
          text: 'Start order',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns2: {
      direction: 'default',
      sizes: [5, 6],
      illustration: {
        src: Image02,
        alt: 'Order Management Made Easy ',
      },
      text: {
        title: 'Order Management Made Easy ',
      },
      list: [
        'Specify quantity, unit price, Incoterms, packaging, and delivery dates.',
        'Provide shipping and billing details to ensure flawless execution.',
        'Track real-time progress on the seller´s side, upon order submission.',
      ],
      buttons: [
        {
          text: 'Start order',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns3: {
      direction: 'reverse',
      sizes: [5, 6],
      illustration: {
        src: Image03,
        alt: 'Digital Handshake for Confidence',
      },
      text: {
        title: 'Digital Handshake for Confidence',
      },
      list: [
        'Enjoy a digital handshake post Order Summary submission for a smooth production.',
        'Sellers promptly confirm details or offer alternatives.',
        'Experience flexibility and collaboration for a mutually beneficial interaction.',
      ],
      buttons: [
        {
          text: 'Start order',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    columns4: {
      direction: 'default',
      sizes: [5, 6],
      illustration: {
        src: Image04,
        alt: 'Fast & Secure Payments',
      },
      text: {
        title: 'Fast & Secure Payments',
      },
      list: [
        'Experience a unique payment management using our integrated escrow service and e-wallets.',
        'Release funds per agreed terms with sellers, finalized upon successful delivery.',
        'Engage confidently with fast and secure payments, ensuring peace of mind for both parties.',
      ],
      buttons: [
        {
          text: 'Start order',
          href: 'SIGNUP',
          color: 'primary',
          size: 'small',
        },
      ],
    },
    cta: {
      title: 'Join now and place orders',
      subhead: 'Try For Free',
      bg: {
        image: background1,
      },
      buttons: [
        {
          text: 'Sign up',
          href: 'SIGNUP',
          color: 'primary',
        },
      ],
    },
  }
}
