// components/TwoImagesSection.js

import React from 'react';
import PropTypes from 'prop-types';
import './TwoImagesSection.scss';
import { Typography } from '@mui/material';

const TwoImagesSection = ({ content }) => {
  return (
    <div className='twoimagesection'>
      <div className='mobileText'>
        <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 5 }} className='pb-s-12 centerText paddingTop'>
          Industry-leading order process
        </Typography>
      </div>
      <div className="two-images-section">
        <div className="image-container">
          <img src={content.image1} alt="For Buyers" />
          <div className="overlay">
            <h2>{content.title1}</h2>
            <p>{content.subtitle1}</p>
            <p>{content.subtitle2}</p>
            <p>{content.subtitle3}</p>
            <p>{content.subtitle4}</p>
            <p>{content.subtitle5}</p>
          </div>
        </div>
        <div className="image-container">
          <img src={content.image2} alt="For Sellers" />
          <div className="overlay">
            <h2>{content.title2}</h2>
            <p>{content.subtitle6}</p>
            <p>{content.subtitle7}</p>
            <p>{content.subtitle8}</p>
            <p>{content.subtitle9}</p>
            <p>{content.subtitle10}</p>
          </div>
        </div>
    </div>
    <div className='centerText margin'>
    <button
      className='btn btn-primary btn-md'
      onClick={() => {location.href=`${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`;
      }}
    >
      Join for free
    </button>
  </div>
  </div>
  );
};

TwoImagesSection.propTypes = {
  content: PropTypes.shape({
    image1: PropTypes.string.isRequired,
    title1: PropTypes.string.isRequired,
    subtitle1: PropTypes.string.isRequired,
    image2: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    subtitle2: PropTypes.string.isRequired,
  }).isRequired,
};

export default TwoImagesSection;
