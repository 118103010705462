import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
// import Benefits from '../components/Benefits'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/Carousel'
import OrdersCover from '../components/ordersCover'
import Columns from '../components/Columns'
import Cta from '../components/Cta'
import Stack from '@mui/material/Stack'
import Testimonials from '../components/Testimonials'
import Toolbar from '@mui/material/Toolbar'
import TrustedBy from '../components/TrustedBy'
import OrdersIcons from '../components/ordersIcons/ordersIcons'
import TwoImagesSection from '../components/twoImagesSection/TwoImagesSection';
import VimeoModule from '../components/vimeoModule/vimeoModule';

// Content
import { contentorders } from '../content/orders'
import { logoSlideContent } from '../content/logoSlide'
import twoImagesSectionContent from '../content/twoImagesSection';

const ordersPage = () => {
  const { t } = useTranslation()
  
  return (
    <Layout headerWhite={true}>
      <Toolbar />
      <OrdersCover />
      <OrdersIcons />
      {/* <span className='text-grey-10 font-size-18 ln-22 pt-s-16'>
        <OrdersIcons />
      </span> */}
      <TwoImagesSection content={twoImagesSectionContent} />
      <Columns content={contentorders(t).columnsCover} />
      <Columns content={contentorders(t).columns1} />
      <Columns content={contentorders(t).columns2} />
      <Columns content={contentorders(t).columns3} />
      <Columns content={contentorders(t).columns4} />
      <VimeoModule
        title={t('ORDER SMARTER, PAY SECURELY')} 
        subtitle={t('All-in-one order system')}
        videoUrl="https://player.vimeo.com/video/928435780" 
      />
      <Stack direction='column' alignItems='center' spacing={2}>
        <TrustedBy mode={'default'} disposition={'row'} text={t('BUSINESSES')} show={false} />
        <Carousel className='mt-s-28' content={logoSlideContent('big', 'default', true).carousel} />
      </Stack>
      <Testimonials />
      <Cta content={contentorders(t).cta} />
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='Orders'
    description='Join the largest tech-enabled network for secure orders and sustainable textile production.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ordersPage
